<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-palette"> </i> 物品借用 | 全部单据
            </h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="warning" @click="create()">新借用单据</el-button>
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态" style="margin-left: 5px">
                <el-option label="处理中" :value="10" />
                <el-option label="借出" :value="15" />
                <el-option label="撤消" :value="20" />
                <el-option label="拒绝" :value="30" />
                <el-option label="完结" :value="90" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="SN" label="单号" width="120" />
              <el-table-column prop="TITLE" label="标题" width="500" />
              <el-table-column label="金额(元)" width="100" align="right">
                <template slot-scope="scope">￥{{ scope.row.AMT }}</template>
              </el-table-column>
              <el-table-column prop="SUB_NAME" label="提交人" width="80" align="center" />
              <el-table-column prop="STEP_DESC" label="步骤" width="150" align="center" />
              <el-table-column prop="STA_TXT" label="状态" width="80" align="center" />
              <el-table-column label="提交日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.CREATED_DT | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <el-table-column label="归还日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.FINISH_DT | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-document" @click="edit(scope.row.ID)">详情</el-button>
                  <!-- <form id="fm_prt" method="post" action="/mat/valid/act/prt" target="_blank" style="display: inline-block; margin: 0px 10px" v-if="scope.row.STA ==90">
                    <input type="hidden" name="ID" v-model="scope.row.ID" /> -->
                  <!-- <el-button native-type="submit" type="text" icon="el-icon-printer" v-if="scope.row.STA == 90"
                    @click="print(scope.row.ID)">
                    <span>打印</span>
                  </el-button> -->
                  <!-- </form> -->
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Create ref="create" @on-saved="filter" />
    </div>
    <div>
      <Edit ref="edit" @on-saved="filter" />
    </div>
    <div>
      <Print ref="print" @on-saved="filter"></Print>
    </div>
  </div>
</template>
<style></style>
<script>
  import Wgrid from "@/components/wgrid";
  import Create from "./create"
  import Edit from "./edit"
  import Print from './prt'
  import jsFileDownload from 'js-file-download'
  export default {
    name: "index",
    components: { Wgrid, Create, Edit, Print },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
            FOR: this.$route.query.for
          },
          ls: [],
          total: 0,
          loading: false
        },
        user: this.whale.user.get(),
      }
    },
    created() {
      this.filter();
    },
    methods: {
      filter() {
        if (this.grid.date_range != null && this.grid.date_range.length > 1) {
          this.grid.sea.DTS = this.grid.date_range[0];
          this.grid.sea.DTE = this.grid.date_range[1];
        } else
          this.grid.sea.DTS = this.grid.sea.DTE = "";

        this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/RENT/RDatApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          }
        })
      },
      create() {
        this.$refs.create.init();
      },
      edit(id) {
        this.$refs.edit.init(id);
      },
      print(id) {
        // this.$refs.print.init(id)
        let self = this;
        this.grid.loading = true;
        const formData = new FormData()
        formData.append('ID', id || '')
        this.whale.remote.getExport({
          url: "/api/School/MAT/MVAct/Prt",
          data: formData,
          completed: function (its) {
            // console.log('exls', its.Data)
            // self.whale.downLoad(its.Data)
            jsFileDownload(its, '单据.xls')
            self.grid.loading = false;
          }
        })
      },
      exportExcel() {
        let self = this
        this.grid.loading = true;
        let model = {
          DTE: self.grid.sea.DTE,
          DTS: self.grid.sea.DTS,
          STA: self.grid.sea.STA,
          KEY: self.grid.sea.KEY,
          FOR: self.$route.query.for,
        }
        this.whale.remote.getExportResult({
          url: "/api/School/RENT/RDatApi/Export",
          data: model,
          completed: function (its) {
            jsFileDownload(its, '借用记录' + new Date().getTime() + '.xlsx')
            self.grid.loading = false;
          }
        })
      }
    }
  }
</script>